import Vue from 'vue';
import VueRouter from 'vue-router';

import BaseRoutes from './base';
import AthlonRoutes from './athlon';
import ProjectRoutes from './project';

Vue.use(VueRouter);

const routes = [].concat(BaseRoutes, AthlonRoutes, ProjectRoutes);

/**
 * Lazy create router while athlon is inited.
 */
export default function createRouter(athlon) {
	const router = new VueRouter({ routes });

	router.addRoutes([{
		path:'/',
		redirect: athlon.config.getLandingPage(),
	}]);

	router.beforeEach((to, from, next) => {
		// 設定語言, 語言設定檔於 src/locale/*.json
		if (to.query.locale) {
            athlon.setLocale(to.query.locale);
		}

        // 設定框架，是否展示頂部欄和側邊菜單欄
        if (to.meta.frameless) {
            athlon.setFrame({ headerBar: false, sideMenu: false });
        } else {
            athlon.setFrame({ headerBar: true, sideMenu: true });
        }

		if('/login' !== to.path){
			if(!athlon.isAuthrizedAccess(to.path)){
				if (!athlon.user.isLogon()) {
					next({path: `/login?page=${encodeURIComponent(to.path)}`});
					return;
				}
			}
		}

		/** 權限檢查 **/
		// 如訪問沒有權限頁面, 跳轉到登錄或錯誤頁面
		// if (!athlon.isAuthrizedAccess(to.path)) {
		// 	if (!athlon.user.isLogon()) {
		// 		next({ path: '/login' });
		// 	} else {
		// 		next({ path: '/error/401' });
		// 	}
		// 	return;
		// }
		/** 權限檢查完結 **/

		next();
	});

	return router;
}
