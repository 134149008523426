import en_US from './en-US.json';
import zh_CN from './zh-CN.json';
import zh_HK from './zh-HK.json';

export default function createI18n(athlon) {
	const messages = {
		'en-US': en_US,
		'zh-CN': zh_CN,
		'zh-HK': zh_HK,
	};
    Object.keys(messages).forEach((locale) => {
        athlon.mergeLocaleMessage(locale, messages[locale]);
    });
	return athlon.i18n;
}
