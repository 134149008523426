<template>
    <ath-app class="ath-project"
        :side-menu-config="dSideMenuConfig"
        @click:login="onLogin"
        @click:logout="onLogout"
        @click:menu="onClickMenu"
        @change:role="onChangeRole"
        @change:locale="onChangeLocale"
    >
		<template v-slot:main>
			<router-view></router-view>
		</template>
	</ath-app>
</template>


<script>
import CommonService from '@/service/common.service';

export default {
    name: 'App',
    data() {
        return {
            /* expandOnHover控制左边的菜单是否可活动true/false,width设置菜单宽度 */
            dSideMenuConfig: {
                expandOnHover: true,
                width: 250
            }
        }
    },
	created() {
        /* eslint-disable no-console */
		console.log('%c ---------------------------', 'color: #B71C1C');
		console.log('%c | WELCOME TO ATHLON WORLD |', 'color: #B71C1C');
		console.log('%c ---------------------------', 'color: #B71C1C');
        /* eslint-enable */

        const $commonService = new CommonService(this.$athlon);
        Vue.prototype.$commonService = $commonService;
	},
	methods: {
        onLogin() {},
        onLogout() {
            this.$loading(this.$t('msg.loading'));
            this.$athlon.request.post('logout/signoff').then((res) => {
                this.$loading(false);
				this.$router.push({
                    path: '/login'
                });
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
        },
        onClickMenu(menu) {
            this.$athlon.setMenuBadge(menu.name, 0);

            if (!menu.path || this.$route.path === menu.path) return;
            this.$router.push({
                path: menu.path,
            });
        },
        onChangeRole(){
            let menus = this.$athlon.getMenuData();
            if(menus.length > 0){
                if(this.$route.path === menus[0].path){
                    this.$router.push({
                        path: menus[0].path,
                        query: {
                            random: new Date()
                        }
                    });
                    return;
                }
                this.$router.push({path: menus[0].path});
            }
        },
        onChangeLocale(){
            let language = this.$athlon.i18n.locale;
            language = language.replace('-', '_');
            let params = {
				random: new Date(),
                locale: language
			};
            this.$loading(this.$t('msg.loading'));
            this.$athlon.request.post('login/changeLocale', params, this.$athlon.config.api.requestParamheaders).then((res) => {
                this.$loading(false);
				if(res.data.retCode && res.data.retCode != '0'){
					this.tipsMsg(this.$t('login.tips.changeLocaleResult.'+res.data.retCode));
					return;
				}
			}).catch(() => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
        }
	}
};
</script>
