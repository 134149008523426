export default function install(Vue, router) {

    const hashchange = function() {
        let currentPath = window.location.hash.slice(1);
        router.push(currentPath);
    }
    
    // 解決在IE11下，Hash手動變化的時候瀏覽器沒有做出響應
    if ('-ms-scroll-limit' in document.documentElement.style &&
        '-ms-ime-align' in document.documentElement.style) {
        window.addEventListener('hashchange', hashchange, false);
    }

}
