import Vue from 'vue';

import createRouter from './router';
import createStore from './store';
import createI18n from './i18n';

import IE11Plugin from './plugins/ie11';
import AppConfig from './app.config';
import App from './App.vue';

import '@/assets/css/main.css';

Vue.config.productionTip = false;

Vue.config.errorHandler = (a, b) => {
    athlon.$.logger.error(a, b);
}

Vue.use(athlon.plugin, AppConfig);

//全局返回处理
athlon.$.handlers.onResponse = function(data) {
	if(/<script(.*?)<\/script>/.test(data)){
		window.location.href = "index.html?timeOut=true";
		return "go2login";
	}
	if(data.code===9999){
        return "exception";
	}

	return data;
}


Vue.prototype.tipsMsg = function(msg) {
	this.$loading(false);
	this.$dialog.open({
		title: this.$t('athlon.common.hint'),
		maxWidth: '20%',
		persistent: false,
		component: {
			ctor: { template: '<div style="color:red">' + msg + '</div>' }
		},
		footBtn: [
			{ eventId: 11, color: 'primary', icon: 'mdi-check', text: this.$t('athlon.common.confirm') }
		],
		onaction: (e) => {
			this.$dialog.close();
		}
	});
}


//网络异常处理
athlon.$.handlers.onNetworkError = function onNetworkError() {
	// window.location.href = "index.html?networkError=true";
	this.tipsMsg(this.$t('msg.networkError'));
    return false;
};

//服务器异常处理
athlon.$.handlers.onServerError = function onServerError() {
	// window.location.href = "index.html?serverError=true";
	this.tipsMsg(this.$t('msg.serverError'));
    return false;
};

Vue.prototype.dealGlobalResponse = function(res) {
	this.$loading(false);
	if (res === 'exception') {
		this.tipsMsg(this.$t('msg.systemError'));
		return true;
	}
	if (res === 'go2login') return true;
	return false;
}

Vue.prototype.downloadByPost = function(url,params) {
	this.$loading(this.$t('msg.loading'));
	this.$athlon.request.post('login/getEnvironmentInfo').then((res) => {//先驗證登錄是否超時，再下載文件
		if(this.dealGlobalResponse(res)) return;
		let loginFlag = res.data.loginFlag || 0;
		if(loginFlag === 1){
			this.$athlon.request.request({
				url: url,
				method: "post",
				data: params,
				headers: {"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"},
				responseType: 'blob',
				withCridentials: true
			}).then((res) => {
				this.$loading(false);
				let fileName = res.headers['content-disposition'].split(";")[1].split("=")[1];
				fileName = decodeURIComponent(fileName);
				let blob = new Blob([res.data]);
				let url = window.URL.createObjectURL(blob);
				let link = document.createElement('a');
				link.style.display = 'none';
				link.href = url;
				link.setAttribute('download',fileName);
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url);
			}).catch((err) => {
				this.$loading(false);
				this.tipsMsg(this.$t('msg.systemError'));
			});
		}else{
			window.location.href = "index.html?timeOut=true";
		}
	}).catch(() => {
		this.$loading(false);
		this.$router.push('/error/500');
	});
}

const i18n = createI18n(athlon.$);
const router = createRouter(athlon.$);
const store = createStore(athlon.$);

Vue.use(IE11Plugin, router);

new Vue({
	i18n,
	router,
	store,
	vuetify: athlon.$.$v,
	render: h => h(App)
}).$mount('#app');
