<template>
	<div class="text-center" style="margin-top:200px">
		<span class="title pt-20" style="color:#aaa;">{{msg}}</span>
	</div>
</template>

<script>

export default {
	name: 'Error',

	components: {
	},

	data: () => ({
		msg: '',
		errCode : ''
	}),

	methods: {
		init() {
			if(this.errCode !== '') {
				this.msg = this.$t('error.' + this.errCode);
			} else {
				this.msg = '';
			}
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.errCode = vm.$route.params.code || '';
			vm.init();
		});
	}
}
</script>
