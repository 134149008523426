import Login from '@/views/login';
import Logout from '@/views/logout';

import Error from '@/views/error';

export default [{
	path: '/login',
	name: 'Login',
    component: Login,
	// component: () => import(
    //     /* webpackChunkName: "login" */
    //     /* webpackMode: "lazy" */
    //     '../views/login.vue'
    // ),
    meta: {
        frameless: true,
    },
}, {
	path: '/logout',
	name: 'Logout',
    component: Logout,
	// component: () => import(
    //     /* webpackChunkName: "logout" */
    //     /* webpackMode: "lazy" */
    //     '../views/logout.vue'
    // ),
}, {
	path: '/error/:code',
	name: 'error',
	component: Error,
}, {
	path:'*',
	redirect: '/error/404',
}];
