/**
 * 以 npm run serve 運行時 process.env.NODE_ENV 的值為 development
 * 以 npm run build 生成的投產程序 process.env.NODE_ENV 的值為 production
 */
import accessControlConfig from './config/accessControl.json';
import apiConfig from './config/api.json';
import menuConfig from './config/menu.json';
import projectConfig from './config/project.json';

export default {
    ...projectConfig,
	api: {
        ...apiConfig,
		host: process.env.NODE_ENV === 'production' ? apiConfig.production_host : apiConfig.development_host,
	},
	accessControl: accessControlConfig,
	menu: menuConfig,
};
