import Vue from 'vue';
import Vuex from 'vuex';

// import StaffStore from './modules/staff.js';

Vue.use(Vuex);

export default function createStore() {
	return new Vuex.Store({
		modules: {
			// StaffStore,
		},
	});
}
