export default class CommonService{
    constructor($athlon) {
        this.$athlon = $athlon;
    }

    formatTitleDate(type) {
        var result = '';
        var gNow = new Date();

        var vNowDay = gNow.getDate();
        var vNowMonth = gNow.getMonth() + 1;
        var vNowYear = gNow.getFullYear();

        //获取当前日
        var vTodayDay = vNowDay < 10 ? "0" + vNowDay : vNowDay;
        var vTodayMon = vNowMonth < 10 ? "0" + vNowMonth : vNowMonth;
        var vToday = vNowYear + "-" + vTodayMon + "-" + vTodayDay;

        // Yesterday
        var vYesterday = new Date(gNow);
        vYesterday.setDate(gNow.getDate() - 1);

        var vYesterdayDay = vYesterday.getDate();
        var vYesterdayMon = vYesterday.getMonth() + 1;
        var vYesterdayYear = vYesterday.getFullYear();
        vYesterdayDay = vYesterdayDay < 10 ? "0" + vYesterdayDay : vYesterdayDay;
        vYesterdayMon = vYesterdayMon < 10 ? "0" + vYesterdayMon : vYesterdayMon;
        vYesterday = vYesterdayYear + "-" + vYesterdayMon + "-" + vYesterdayDay;

        // 7 Days Before
        var vSevenDayBefore = new Date(gNow);
        vSevenDayBefore.setDate(gNow.getDate() - 7);

        var vSevenDayBeforeDay = vSevenDayBefore.getDate();
        var vSevenDayBeforeMon = vSevenDayBefore.getMonth() + 1;
        var vSevenDayBeforeYear = vSevenDayBefore.getFullYear();
        vSevenDayBeforeDay = vSevenDayBeforeDay < 10 ? "0" + vSevenDayBeforeDay : vSevenDayBeforeDay;
        vSevenDayBeforeMon = vSevenDayBeforeMon < 10 ? "0" + vSevenDayBeforeMon : vSevenDayBeforeMon;
        vSevenDayBefore = vSevenDayBeforeYear + "-" + vSevenDayBeforeMon + "-" + vSevenDayBeforeDay;

        // Last Year
        var vLastYear = gNow.getFullYear() - 1;

        // 3 Months Before
        var v3MonthBefore = new Date(gNow);
        v3MonthBefore.setMonth(gNow.getMonth() - 3);

        var v3MonthBeforeDay = v3MonthBefore.getDate();
        var v3MonthBeforeMon = v3MonthBefore.getMonth() + 1;
        var v3MonthBeforeYear = v3MonthBefore.getFullYear();
        //v3MonthBeforeDay = v3MonthBeforeDay < 10 ? "0" + v3MonthBeforeDay : v3MonthBeforeDay;
        v3MonthBeforeDay = "01";
        v3MonthBeforeMon = v3MonthBeforeMon < 10 ? "0" + v3MonthBeforeMon : v3MonthBeforeMon;
        v3MonthBefore = v3MonthBeforeYear + "-" + v3MonthBeforeMon + "-" + v3MonthBeforeDay;

        // 6 Months Before
        var v6MonthBefore = new Date(gNow);
        v6MonthBefore.setMonth(gNow.getMonth() - 6);

        var v6MonthBeforeDay = v6MonthBefore.getDate();
        var v6MonthBeforeMon = v6MonthBefore.getMonth() + 1;
        var v6MonthBeforeYear = v6MonthBefore.getFullYear();
        //v6MonthBeforeDay = v6MonthBeforeDay < 10 ? "0" + v6MonthBeforeDay : v6MonthBeforeDay;
        v6MonthBeforeDay = "01";
        v6MonthBeforeMon = v6MonthBeforeMon < 10 ? "0" + v6MonthBeforeMon : v6MonthBeforeMon;
        v6MonthBefore = v6MonthBeforeYear + "-" + v6MonthBeforeMon + "-" + v6MonthBeforeDay;

        if (type === 'day')             //day 2019-12-04
          result = vToday;
        else if (type === 'month')      //month 2019-12-01 - 2019-12-04
          result = vNowYear + "-" + vTodayMon + "-01 - " + vToday;
        else if (type === 'year')       //year 2019-01-01 - 2019-12-04
          result = vNowYear + "-01-01 - " + vToday;
        else if (type === 'lastyear')   //last year 2018-01-01 - 2018-12-31
          result = vLastYear + "-01-01 - " + vLastYear + "-12-31";
        else if (type === '7day')       //seven days before 2019-11-28 - 2019-12-04
          result = vSevenDayBefore + " - " + vYesterday;
        else if (type === '3month')       //3 months before 2019-09-05 - 2019-12-04
          result = v3MonthBefore + " - " + vYesterday;
        else if (type === '6month')       //6 months before 2019-06-05 - 2019-12-04
          result = v6MonthBefore + " - " + vYesterday;

        return result;
    }
}
