/* eslint-disable */
export default [
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home.vue'),
        /* 加上这个参数可以去除左侧菜单和顶部导航，两者同时存在，不能单独去除某一个 */
        meta: {
            frameless: true,
        }
    },
    {
        path: '/tradeAnalysis',
        name: 'TradeAnalysis',
        component: () => import('@/views/trans_anal/analysis.vue'),
        meta: {
            frameless: false,
        }
    },
    {
      path: '/transRec',
      name: 'TransRec',
      component: () => import('@/views/trans_rec/trans_rec_list.vue')
    },
    {
      path: '/mersInfo',
      name: 'MersInfo',
      component: () => import('@/views/mers/mers_info_list.vue')
    },
    {
      path: '/userInfo',
      name: 'UserInfo',
      component: () => import('@/views/user/user_info.vue')
    },
    {
       path: '/billDownload',
       name: 'BillDownload',
       component: () => import('@/views/bill/bill_download.vue')
    }
];
