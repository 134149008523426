<template>
	<div class="text-center" style="margin-top:200px">
		<span class="headline pt-20" style="color:#aaa;">{{$t('msg.logoutSuccess')}}</span>
	</div>
</template>

<script>
export default {
	name: 'logout',

	components: {
	},

	data: () => ({
	}),

	methods: {
		doLogout() {
			this.clearLocalData();
        },
		clearLocalData() {
			this.$athlon.setRoleList(null);
			this.$athlon.setUserData(null);
		}
	},
	beforeRouteEnter(to, from , next) {
		next(vm => {
			vm.doLogout();
		});
	}
}
</script>
